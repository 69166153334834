import React from "react";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a
        href="https://twitter.com/ReeceLondon"
        target="_blank"
        rel="noreferrer"
        className="app__social-links"
        aria-label="Link to Reece's Twitter page"
      ><span className="sr-only">Twitter link</span>
        <BsTwitter />
      </a>
    </div>
    <div>
      <a
        href="https://www.instagram.com/reecelondonmoore"
        target="_blank"
        rel="noreferrer"
        aria-label="Link to Reece's Instagram page"
      ><span className="sr-only">Instagram link</span>
        <BsInstagram />
      </a>
    </div>
    <div>
      <a
        href="https://www.linkedin.com/in/reecemoore"
        target="_blank"
        rel="noreferrer"
        aria-label="Link to Reece's LinkedIn page"
      ><span className="sr-only">LinkedIn link</span>
        <BsLinkedin />
      </a>
    </div>
  </div>
);

export default SocialMedia;
