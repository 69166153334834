import React from "react";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";

const FooterSocialMedia = () => (
  <div className="app__social-footer">
    <div>
      <a
        href="https://twitter.com/ReeceLondon"
        target="_blank"
        rel="noreferrer"
      ><span className="sr-only">Twitter link</span>
        <BsTwitter />
      </a>
    </div>
    <div>
      <a
        href="https://www.instagram.com/reecelondonmoore"
        target="_blank"
        rel="noreferrer"
      ><span className="sr-only">Instagram link</span>
        <BsInstagram />
      </a>
    </div>
    <div>
      <a
        href="https://www.linkedin.com/in/reecemoore"
        target="_blank"
        rel="noreferrer"
      ><span className="sr-only">LinkedIn link</span>
        <BsLinkedin />
      </a>
    </div>
  </div>
);

export default FooterSocialMedia;
