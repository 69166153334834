import React from "react";

const NavigationDots = ({ active }) => (
  <div className="app__navigation">
    {["home", "about", "work", "skills", "contact"].map((item, index) => (
      <a
        aria-label={`${item} section of the website`}
        href={`#${item}`}
        key={item + index}
        className="app__navigation-dot"
        style={active === item ? { backgroundColor: "hsl(164, 45%, 35%)" } : {}}
      />
    ))}
  </div>
);

export default NavigationDots;
