import React, { useState } from "react";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import { FooterSocialMedia } from "../../components";
import "./Footer.scss";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  const initialState = {
    username: "",
    email: "",
    message: "",
  };

  const clearState = () => {
    setFormData(initialState);
  };

  const resubmitForm = () => {
    setIsFormSubmitted(false);
  };

  return (
    <>
      <h2
        className="head-text"
        aria-label="Start of the get in touch section of the website"
      >
        Get In Touch
      </h2>
      <FooterSocialMedia />
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <label for="username" className="sr-only form-Label p-text">
              Name:
            </label>
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="username"
              id="username"
              value={username}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <label for="email" className="sr-only form-Label p-text">
              {" "}
              Email Address:
            </label>
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              id="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <label for="message" className="sr-only form-Label p-text">
              Message:
            </label>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              id="message"
              onChange={handleChangeInput}
            />
          </div>
          <section className="form_buttons">
            <button
              type="button"
              className="p-text form_buttons"
              onClick={clearState}
            >
              Clear Form
            </button>
            <button
              type="button"
              className="p-text form_buttons"
              onClick={handleSubmit}
            >
              {!loading ? "Send Message" : "Sending..."}
            </button>
          </section>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch!</h3>
          <section className="reset-button_container">
            <button
              type="button"
              className="p-text reset-form__button"
              onClick={resubmitForm}
            >
              Reset Form
            </button>
          </section>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
